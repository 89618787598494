import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PartnerDbModel } from '@data-models/partner-db.model';
import { TranslateModule } from '@ngx-translate/core';
import { PartnerService } from '@services/feature-service/partner.service';

@Component({
  standalone: true,
  imports: [NgIf, NgClass, TranslateModule],
  selector: 'app-calio-footer',
  templateUrl: './calio-footer.component.html'
})
export class CalioFooterComponent implements OnInit {

  showFooter = false;
  partnerLoadEvent$: any;
  partner = new PartnerDbModel();
  currYear = new Date().getFullYear();

  constructor(
    private partnerService: PartnerService
  ) { }

  ngOnInit() {
    this.partnerLoadEvent$ = this.partnerService.partnerLoadEvent.subscribe(partner => {
      if (partner?.uuid && partner?.has_active_subscription) {
        this.partner = partner;
        this.showFooter = !this.partnerService.isWhitelabeledPartner;
      }
      this.partnerLoadEvent$.unsubscribe();
    });
  }
}
