<div
  class="cbp-text-center cbp-text-xs cbp-font-semibold"
  [ngClass]="{
    'cbp-mb-10': !showFooter,
    'cbp-mt-5 cbp-mb-5': showFooter
  }"
>
  <ng-container *ngIf="showFooter">
    <span class="cbp-text-calenso-neon-blue-color">
      Calenso AG
    </span>
    <span>{{ 'footer.copyright_text' | translate: { years: '2016 - ' + currYear } }}</span>
  </ng-container>
</div>
