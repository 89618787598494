<nav id="header" class="cbp-flex cbp-items-center cbp-bg-calenso-header-background-color cbp-h-[60px] max-md:cbp-px-3 max-md:cbp-h-[50px]">
  <div class="cbp-container cbp-mx-auto cbp-flex cbp-items-center cbp-justify-between">
    <div class="cbp-flex cbp-items-center cbp-flex-shrink-0 cbp-text-white cbp-mr-6 cbpc-partner-logo">
      <ng-container *ngIf="logo">
        <a [routerLink]="">
          <img
            appImgFallback="/images/logo/calenso_logo_new.svg"
            rel="preload"
            fetchPriority="high"
            class="cbp-h-10"
            height="40"
            src="{{ logo }}"
            attr.alt="{{ partner?.name || undefined }}"
            attr.title="{{ partner?.name || undefined }}"
          />
        </a>
      </ng-container>
    </div>


    <ng-container *ngIf="!internal && !isIframe">
      <ng-container *ngIf="languages">
        <ng-container *ngIf="supportedLanguages?.length > 1">
          <div class="cbpc-partner-languages">
            <button [matMenuTriggerFor]="menu" class="cbcp-selected-language" aria-label="Example icon-button with a menu">
              <span>
                <ng-container *ngFor="let language of languages">
                  <img
                    priority
                    class="flag-icon"
                    height="20"
                    width="20"
                    *ngIf="lang === language.locale"
                    [ngSrc]="(deployUrl + language.flagIcon) | brokenImageHandler"
                    alt="{{ language.text | titlecase }}"
                    title="{{ language.text | titlecase }}"
                  />
                </ng-container>
              </span>
            </button>

            <mat-menu class="cbp-mt-3 !cbp-min-w-[50px] cbcp-language-list" #menu="matMenu" xPosition="before">
              <ng-container *ngFor="let language of languages">
                <a
                  mat-menu-item
                  *ngIf="lang !== language.locale && supportedLanguages && supportedLanguages.indexOf(language.value) > -1"
                  [routerLink]=""
                  (click)="switchLanguage(language.locale)"
                >
                  <img
                    class="cbp-w-5"
                    height="20"
                    width="20"
                    [ngSrc]="(deployUrl + language.flagIcon) | brokenImageHandler"
                    alt="{{ language.text | titlecase }}"
                    title="{{ language.text | titlecase }}"
                    (click)="switchLanguage(language.locale)"
                  />
                </a>
              </ng-container>
            </mat-menu>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</nav>
